import React from "react";
import "./landing_style.css";

function Landing() {
  return (
    <main id="landing-main" className="main-container">
      <div className="construction-content">
        <img
          loading="lazy"
          src="Logo_white_800px.png"
          alt="Logo"
          className="logo-image"
        />
        <section className="content-container">
          <h1 className="title">Unsere Website wird überarbeitet</h1>
          <p className="subtitle">Wir sind bald mit einem neuen Look zurück!</p>

          <div className="contact-info">
            <h2>So erreichst Du uns:</h2>

            <div className="contact-item">
              <a href="https://www.google.com/maps/dir//Maingasse+26,+97877+Wertheim/" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="contact-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                     fill="none" stroke="currentColor" strokeWidth="2" 
                     strokeLinecap="round" strokeLinejoin="round" 
                     className="contact-icon">
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                  <circle cx="12" cy="10" r="3"></circle>
                </svg>
                <span>Unsere Adresse besuchen</span>
              </a>
            </div>

            <div className="contact-item">
              <a href="tel:093429181603" className="contact-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                     fill="none" stroke="currentColor" strokeWidth="2" 
                     strokeLinecap="round" strokeLinejoin="round" 
                     className="contact-icon">
                  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                </svg>
                <span>Anrufen</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Landing;